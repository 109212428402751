<template>
  <div
    v-show="isVisible"
    class="relative z-50"
    @click.self="close">
    <div
      class="fixed inset-0 bg-gray bg-opacity-75 transition-opacity"
      aria-hidden="true"
      @click="close">
    </div>
    <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
      <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
        <div class="relative transform overflow-hidden rounded-xl bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl">
          <div class="flex justify-between flex-row px-4 py-3 border-b border-gray bg-slate-100">
            <span class="text-xl font-medium fw-400 text-slate-500">
              Share your article
            </span>
            <xdem-icon
              icon="bg-xdem-close"
              size="24"
              @click="close"
            />
          </div>

          <div class="flex flex-row items-center w-full pr-2">
            <div
              v-for="(item, idx) in shareTos"
              :key="idx"
              class="w-32 py-2 cursor-pointer"
              :class="`${activeTab === idx ? 'border-b-2 border-primary' : ''}`"
              @click="activeTab = idx">
              <span
                class="text-base text-center"
                :class="`${activeTab === idx ? 'text-primary font-semibold' : 'text-slate-400 font-normal'}`">
                {{ item }}
              </span>
            </div>
          </div>
          <div
            v-if="activeTab === 0"
            class="flex flex-col px-2 py-2 sm:px-5 sm:py-4 sm:mt-0 max-h-[500px] overflow-auto overflow-x-hidden">
            <div class="flex flex-row items-center pb-4 sm:pb-0">
              <img
                :src="userData.avatar"
                :title="userData.name"
                class="w-[32px] h-[32px] rounded-full bg-cover bg-center bg-no-repeat">
              <div class="flex flex-col ml-2">
                <div class="flex flex-row items-center justify-start">
                  <span class="text-base font-medium text-slate-700 line-clamp-1">
                    {{ userData.name }}
                  </span>
                  <xdem-icon
                    v-show="userData.isVerified"
                    icon="bg-xdem-verified"
                    size="16"
                    custom="ml-1"/>
                  <div
                    v-show="userData.headline"
                    class="rounded-l-[30px] rounded-r-[30px] bg-[#089dd9] ml-1">
                    <span class="text-xs font-medium text-white px-2 py-0.5">
                      {{ userData.headline }}
                    </span>
                  </div>
                </div>
                <div class="flex flex-row items-center">
                  <span class="text-xs font-normal text-primary-dark">
                    {{ userData.current_institute }}
                  </span>
                  <span
                    v-show="userData.headline && userData.current_institute"
                    class="px-1">|</span>
                  <span
                    v-show="userData.current_organisation"
                    class="text-xs font-normal text-primary-dark">
                    {{ userData.current_organisation }}
                  </span>
                </div>
              </div>
            </div>
            <smart-editor
              :key="`share-content`"
              :text="content"
              placeholder="Create a post"
              :set-value="val => content = val"
              add-class="my-4 min-h-[40px] min-h-fit"
            />
            <div
              v-if="data.media"
              :style="{ backgroundImage:
                `url(${data.media})`}"
              class="flex items-end justify-end bg-cover bg-no-repeat w-full min-h-[300px]">
              <div class="bg-secondary--500 flex justify-center items-center h-[40px] w-full">
                <span class="text-md font-normal text-white">
                  {{ data.title }}
                </span>
              </div>
            </div>
          </div>
          <div
            v-else
            class="flex flex-col px-2 py-2 sm:px-5 sm:py-4 sm:mt-0 max-h-[500px] overflow-auto overflow-x-hidden">
            <div class="flex flex-col  gap-6 w-full pb-4 sm:pb-0">
              <div class="flex gap-0 justify-around items-center ">
                <a
                  :href="`https://api.whatsapp.com/send/?phone&text=${url}`"
                  target="_blank"
                  class="flex flex-col items-center"
                >
                  <span class="fa-x-wa-rounded fa-xl" />
                  <span class="text-share-link">WhatsApp</span>
                </a>
                <a
                  :href="`https://www.facebook.com/sharer/sharer.php?u=${url}`"
                  target="_blank"
                  class="flex flex-col items-center"
                >
                  <span class="fa-x-facebook-rounded fa-xl" />
                  <span class="text-share-link">Facebook</span>
                </a>
                <a
                  :href="`https://twitter.com/intent/tweet?&url=${url}`"
                  target="_blank"
                  class="flex flex-col items-center"
                >
                  <span class="fa-x-twitter-rounded fa-xl" />
                  <span class="text-share-link">X</span>
                </a>
                <a
                  :href="`https://www.linkedin.com/sharing/share-offsite/?url=${url}`"
                  target="_blank"
                  class="flex flex-col items-center"
                >
                  <span class="fa-x-linkedin-rounded fa-xl" />
                  <span class="text-share-link">LinkedIn</span>
                </a>
                <a
                  :href="`http://pinterest.com/pin/create/button/?url=${url}`"
                  target="_blank"
                  class="flex flex-col items-center"
                >
                  <span class="fa-x-pinterest-rounded fa-xl" />
                  <span class="text-share-link">Pinterest</span>
                </a>
                <a
                  :href="`mailto:?body=${url}`"
                  target="_blank"
                  class="flex flex-col items-center"
                >
                  <span class="fa-x-mail-rounded fa-xl" />
                  <span class="text-share-link">Email</span>
                </a>
              </div>
              <div class="flex rounded-lg shadow-sm my-3">
                <input 
                :value="url"
                readonly="true"
                type="text" class="py-3 px-4 w-full border border-l-primary--600 border-t-neutral-400 border-b-neutral-400 rounded-s-lg text-sm outline-none">
                <button
                  type="button"
                  class="py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-e-md  bg-primary hover:bg-primary-600 focus:outline-none focus:bg-primary-700 disabled:opacity-50 disabled:pointer-events-none"
                  @click="copy">
                  <span class="text-base font-medium text-white">Copy</span>
                </button>
              </div>
            </div>
          </div>
          <div>
        </div>
          <div
            v-if="activeTab === 0"
            class="bg-gray-50 px-3 py-3 sm:flex sm:flex-row-reverse border-t border-gray">
            <button
              type="button"
              class="inline-flex w-full justify-center bg-primary hover:bg-primary--600 active:bg-primary--700 rounded-md px-3 py-2 shadow-sm sm:ml-3 sm:w-auto sm:min-w-[100px]"
              @click="save">
              <span class="text-base font-medium text-white">
                Share
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useAuthStore } from '@/store';
import SmartEditor from '@/components/SmartEditor';

export default {
  name: 'ShareToTimelineModal',
  components: {
    SmartEditor,
  },
  props: {
    isVisible: {
      type: Boolean,
      required: false,
      default: false
    },
    data: {
      type: Object,
      required: true,
    },
    close: {
      type: Function,
      required: false
    },
  },
  data() {
    return {
      content: '',
      shareTos: ['Timeline','Other platform'],
      activeTab: 0,
      url: '',
    };
  },
  computed: {
    userData() {
      return useAuthStore().user;
    },
  },
  created() {
    this.url = process.env.VUE_APP_PARENT_URL+'/newsroom/'+this.data.uuid;
  },
  methods: {
    copy() {
      navigator.clipboard.writeText(this.url);
      this.$toast.open({
        message: 'Success',
        type: 'success',
      });
    },
    async save() {
      await this.$http.put('/share', {
        type: 'newsroom',
        shareId: this.data.uuid,
        shareTo: 'toMe',
        parties: [],
        hashtags: null,
        content: this.content,
        mentions: [],
        isShareContent: false,
      }).then((res) => {
        if(res) {
          this.$toast.open({
            message: 'Success',
            type: 'success',
          });
          this.close();
        }
      }).catch(() => {
        this.$toast.open({
            message: 'Failed to share as a post',
            type: 'error',
          });
      });
    }
  }
};
</script>
